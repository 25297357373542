import React, {Component, Suspense, useEffect, useState} from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';
import './scss/style.scss';
import {updateLoginInfo, getLoginInfo} from './store';
import {useSelector, useDispatch} from 'react-redux';
import {initUser} from './utility/UtilityMethods';
import Loader from './components/Loader';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function PublicRouting(props) {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path="/login"
            name="Login Page"
            element={<Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={<Register />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<Login {...props} />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

function PrivateRouting() {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default function App() {
  const [reload, setReload] = useState(true);
  const {
    payload: {
      userInfo: {token, isLogin, user},
    },
  } = useSelector(getLoginInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      const {isAuth, ...rest} = await initUser({user, token});
      if (isAuth) {
        dispatch(updateLoginInfo({...rest, isLogin: true}));
        setReload(false);
      } else {
        setReload(false);
      }
    }
    if (!token) {
      fetchData();
    }
    return () => {
      console.log('Unmount main app');
    };
  });
  const setLogin = ({user, token}) => {
    dispatch(updateLoginInfo({token, isLogin: true, user}));
  };

  const com = reload ? (
    <Loader />
  ) : (
    (isLogin && <PrivateRouting />) || <PublicRouting setLogin={setLogin} />
  );
  return com;
}
