import React, {useContext, useMemo} from 'react';
import {getClosestBreakpoint} from './Utility';
import {useWindowDimensions} from './useWindowDimensions';

const MediaQueryContext = React.createContext();
const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const MediaQueryProvider = ({children}) => {
  const windowWidth = useWindowDimensions()?.width;
  const currentBreakpoint = useMemo(
    () => getClosestBreakpoint(breakpoints, windowWidth),
    [windowWidth, breakpoints],
  );

  const providerValue = useMemo(
    () => ({
      current: Object.keys(breakpoints)[currentBreakpoint],
      width: windowWidth,
      breakpoints,
    }),
    [breakpoints, currentBreakpoint, windowWidth],
  );

  return (
    <MediaQueryContext.Provider value={providerValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useScreenDim = () => {
  const value = useContext(MediaQueryContext);
  return value;
};

export {MediaQueryContext};
