import {useState, useEffect, useCallback} from 'react';

export function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window?.innerWidth : null;
    const height = hasWindow ? window?.innerHeight : null;
    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    if (hasWindow) {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowDimensions(getWindowDimensions());
      }
      // Add event listener
      window.addEventListener('resize', handleResize);
      if (!windowDimensions.width && !windowDimensions.height) {
        handleResize();
      }
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [
    hasWindow,
    getWindowDimensions,
    windowDimensions.width,
    windowDimensions.height,
  ]); // Empty array ensures that effect is only run on mount
  return windowDimensions;
}
