let envKey =
  "-----BEGIN RSA PUBLIC KEY-----\nMIIBCgKCAQEArE3+rJGn4yBe8QkQl0g9KWHSoZ5Z6LOwU1gk6NGbD1yzVGnFJ/YeCMJCLt1e\nn9fjD23MEtetofws6Du5iToA2YACVugSclQD4D25H2w/ICjTfMNGxbyr4vJjsE9hUt7E0wAL\nePMGnaT27hXkI0MXrlfpN05FyAXqUkzyoHpSekHOZZt1ysDycva4AW9lVpqhQwG/Uw2VEFOR\nvrs7ztLrzQE55g8DD5pxafpiE9dIDf9DRbu63KseCWZEB2rDafQdNGhDYQznEsaIeCvUUq2O\n4XAlGhut/BdyiZMSm/mChG5Tt3C3Ag3+4v8Etol1mqbuKiByzCMy6+B1vM8QgXONhQIDAQAB\n-----END RSA PUBLIC KEY-----\n";
let baseUrl = "https://apis.titanicppl.com/v1/admin/api/";
// baseUrl = "http://localhost:9800/v1/admin/api/";

const imageurl = "https://apis.titanicppl.com/images/";

const adminUri = "/v1/admin/api/";

export { baseUrl, adminUri, envKey, imageurl };
