import React from "react";
import { CToast, CToastBody, CToastClose, CToastHeader, CToaster } from "@coreui/react";

export function ToastComponent({ toast, type }) {
  const headerColor = type == "Success" ? "#29AB87" : "#D21F3C";
  return (
    <div
      style={{
        position: "fixed",
        top: "40%",
        left: "40%"
      }}
    >
      <CToast animation={true} autohide={false} visible={true}>
        <CToastHeader closeButton style={{ backgroundColor: headerColor }}>
          <div style={{
            color: '#FFF'
          }} className="fw-bold me-auto">Message</div>
        </CToastHeader>
        <CToastBody>{toast.message}</CToastBody>
      </CToast>
    </div>
  );
}
