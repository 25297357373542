import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ToastComponent } from "./Toast";

export const ToastContext = React.createContext();

const useToastState = () => {
  let [toast, setState] = useState({});
  let timerRef = useRef();

  const hideToast = useCallback(() => {
    const newState = {
      ...toast,
      message: void 0,
      type: void 0,
      time: void 0,
      visible: false
    };
    setState(newState);
  }, [toast]);

  const show = useCallback(
    ({ message, type = "Success", time = 2000 }) => {
      let newState = { ...toast, message, type, time, visible: true };
      setState(newState);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(hideToast, time);
    },
    [toast, timerRef]
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  return { toast, show };
};

export const ToastProvider = ({ children }) => {
  const { toast, show } = useToastState();
  const value = useMemo(() => {
    return { toast, show };
  }, [toast, show]);
  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
  const { show } = useContext(ToastContext);
  return show;
};

export const Toast = () => {
  const { toast } = useContext(ToastContext);
  // console.log("toast>>>>>>>>>>>", toast);
  const { visible, message, type = "Success" } = toast;
  if (!visible || !message) {
    return null;
  }

  if (type === "Success") {
    return <ToastComponent toast={toast} type={type} />;
  } else if (type === "Error") {
    return <ToastComponent toast={toast} type={type} />;
  } else {
    return null;
  }
};
