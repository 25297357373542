import {configureStore, combineReducers, createSlice} from '@reduxjs/toolkit';

// export const counterSlice = createSlice({
//   name: "counter",
//   initialState: {
//     value: 0
//   },
//   reducers: {
//     increment: state => {
//       // Redux Toolkit allows us to write "mutating" logic in reducers. It
//       // doesn't actually mutate the state because it uses the immer library,
//       // which detects changes to a "draft state" and produces a brand new
//       // immutable state based off those changes
//       state.value += 1;
//     },
//     decrement: state => {
//       state.value -= 1;
//     },
//     incrementByAmount: (state, action) => {
//       state.value += action.payload;
//     }
//   }
// });

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    token: null,
    isLogin: false,
    sidebarShow: true,
    loadOrders: true,
  },
  reducers: {
    setSidebar: state => {
      return {...state, sidebarShow: !state.sidebarShow};
    },
    setLoadOrders: state => {
      return {...state, loadOrders: !state.loadOrders};
    },
    getLoadOrders: state => {
      return {...state, loadOrders: state.loadOrders};
    },
    getSidebar: state => {
      return {...state, sidebarShow: state.sidebarShow};
    },
    getLoginInfo: state => {
      const info = {
        token: state.token,
        user: state.user,
        isLogin: state.isLogin,
      };
      return info;
    },
    updateLoginInfo: (state, {payload: {token, isLogin, user}}) => {
      state.token = token;
      state.isLogin = isLogin;
      state.user = user;
    },
  },
});

export const {
  updateLoginInfo,
  getLoginInfo,
  getSidebar,
  setSidebar,
  getLoadOrders,
  setLoadOrders,
} = userSlice.actions;

const reducer = {
  userInfo: userSlice.reducer,
};

export default configureStore({
  reducer: {
    ...reducer,
  },
});
