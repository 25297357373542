import { baseUrl, envKey, imageurl } from "src/config";
import Storage from "./LocalStorage";
import axios from "axios";
import moment from "moment";
import { getEncryptedParams } from "./encryption/Encryption";
let userData;
let tokenData;

const getUser = () => userData;
const getImageUrl = () => imageurl;

const logout = async () => {
  try {
    const uri = "logout";
    await Storage.removeItem("token");
    await loadData({ uri, method: "get" });
  } catch (err) {}
};

const loadData = async ({ props, uri, method = "post" }) => {
  try {
    let res;
    const headers = {
      "Content-Type": "application/json",
      "x-access-token": `${tokenData}`
    };
    if (userData && userData.type == "admin") {
      props = { ...props, organization: userData.organization || "xxxx" };
    }
    if (props) {
      // props = getEncryptedParams(props, envKey);
    }
    if (method === "post") {
      res = await axios.post(`${baseUrl + uri}`, props, { headers });
    } else {
      res = await axios.get(`${baseUrl + uri}`, { ...props, headers });
    }
    if (res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in network call", err);
    const error = new Error(err);
    throw error;
  }
};

const saveData = async ({ props, uri }, headers = {}) => {
  try {
    const defaultHeaders = {
      "Content-Type": "application/json",
      "x-access-token": `${tokenData}`,
      ...headers
    };
    if (props) {
      // props = getEncryptedParams(props, envKey);
    }
    if(props['serialNumber']){
      delete props['serialNumber'];
    }
    const res = await axios.post(`${baseUrl + uri}`, props, { headers: defaultHeaders });
    if (res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in network call");
    const error = new Error(err);
    throw error;
  }
};

const authenticateUser = async ({ email, password }) => {
  const props = {
    email,
    password
  };
  const response = await loadData({ props, uri: "login" });
  if (response?.data) {
    userData = response.data;
    tokenData = response.token;
    await Storage.setItem("token", tokenData);
    return { isAuth: true, user: userData, token: tokenData };
  }
  return response;
};

const loadUser = async () => {
  try {
    const token = await Storage.getItem("token");
    if (token) {
      const uri = "loaduser";
      tokenData = token;
      const response = await loadData({ uri, method: "get" });
      if (response?.data) {
        userData = response.data;
        return { isAuth: true, user: userData, token: tokenData };
      } else {
        return { user: null, token: null };
      }
    } else {
      return { user: null, token: null };
    }
  } catch (error) {
    return { user: null, token: null };
  }
};

const initUser = async ({ user, token }) => {
  if (user && token) {
    userData = user;
    tokenData = token;
    return { isAuth: true, user, token };
  }
  return loadUser();
};

const getDateFilter = (filter = "") => {
  const from = moment().startOf(filter.toLowerCase());
  const to = moment().endOf(filter.toLowerCase());
  return { from, to };
};

export { authenticateUser, initUser, logout, loadData, saveData, getDateFilter, getUser, getImageUrl };
