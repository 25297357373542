import React, {useEffect, useState} from 'react';
import {CSpinner} from '@coreui/react';
const Loader = () => {
  return (
    <div
      id="loader"
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '20%',
      }}>
      <CSpinner color="info" />
    </div>
  );
};
export default React.memo(Loader);
